import { Card, Elevation } from "@blueprintjs/core";
import { ThumbnailType } from "../../../src/models/map/thumbnailtypes";

type Props = {
    mapId: number;
}

export default function NotLoggedInMapCard(
    {
        mapId,
    }
): JSX.Element {
    return <Card
        interactive={false}
        elevation={Elevation.ZERO}
    >
        <picture>
            <img
                alt="map"
                width={128}
                height={128}
                src={`/api/map/getthumbnail?mapId=${mapId}&type=${ThumbnailType.Icon}`}
            />
        </picture>
    </Card>

}

import { ApiGame } from "../../src/types/apigame"
import HomeGamesHeaderCardWithGames from "./gamesheadercardwithgames";
import styles from './gamesheadercard.module.css';
import HomeGamesHeaderCardCreateGameButton from "./creategame";
import { Callout, Intent } from "@blueprintjs/core";

type Props = {
    games: ApiGame[],
};

export default function HomeGamesHeaderCard(
    {
        games,
    }: Props,
): JSX.Element {
    /**
     * Blueprint's stupid Callout component will statically place any
     * icon exactly 17px from the top. So if you have a big div and want
     * to vertically center the content TOO BAD. The icon is always 17px
     * from the top. So you have to manually kill the built-in icon
     * and place your own.
     */
    return <Callout
        className={styles.container}
        icon={null}
        intent={Intent.PRIMARY}
    >
        <HomeGamesHeaderCardWithGames numGames={games.length} />
        <HomeGamesHeaderCardCreateGameButton />
    </Callout>;
}

import { Intent } from '@blueprintjs/core';
import SButton from '../../global/sbutton';
import styles from './card.module.css';
import { useCallback, useState } from 'react';
import LoginDialog from '../../login/logindialog';
import { HomepageStatus } from '../../login/accountformcontainer';

export default function NotLoggedInPlayButton(): JSX.Element {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onClick = useCallback(
        () => setIsOpen(true),
        [setIsOpen],
    );

    const onClose = useCallback(
        () => setIsOpen(false),
        [setIsOpen],
    );

    return (
        <SButton
            className={styles.playButton}
            intent={Intent.SUCCESS}
            large={true}
            onClick={onClick}
        >
            Play Now
            <LoginDialog
                isOpen={isOpen}
                onClose={onClose}
                status={HomepageStatus.RegisterAccount}
            />
        </SButton>
    );
}

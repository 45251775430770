import Head from "next/head";

interface Props { subtitle?: string };

export default function Title(
    { subtitle }: Props,
) {
    const subtext = subtitle
        ? ` - ${subtitle}`
        : '';

    return (
        <Head>
            <title>{`S•P•Q•R${subtext}`}</title>
        </Head>
    )
}
import { memo } from "react";
import { ReactSVG } from "react-svg";
import { ENVIRONMENT_IS_DEVELOPMENT } from "../../../src/util/development";

type Props = {
    asset: string;
    afterInjection?: (svg: SVGSVGElement) => void;
    beforeInjection?: (svg: SVGSVGElement) => void;
};

export function MapSVGUnmemoized({
    asset,
    afterInjection,
    beforeInjection,
}: Props): JSX.Element {
    // don't use the CDN for local development, or if it's a blob
    const srcUrl = ENVIRONMENT_IS_DEVELOPMENT || asset.substring(0, 5) === 'blob:'
        ? asset
        : `https://static.spqr.app/public${asset}`;

    return <ReactSVG
        src={srcUrl}
        afterInjection={afterInjection}
        beforeInjection={beforeInjection}
        renumerateIRIElements={false}
    />;
}

export const MapSVGMemoized = memo(
    MapSVGUnmemoized,
    (oldProps: Props, newProps: Props) => {
        return oldProps.asset === newProps.asset;
    },
);

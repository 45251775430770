import { useCallback, useEffect, useRef, useState } from "react";
import { MapSVGMemoized } from "../../map/mapmemo";
import styles from './mapzoom.module.css';
import { ApiMapCountry, ApiMapData } from "../../../../src/types/apimap";
import { ApiCountry } from "../../../../src/types/apigame";

const NEXT_COUNTRY_DELAY_MS = 15000;

type Props = {
    asset: string;
    mapData: ApiMapData;
    darkness?: number;
}

function getTopLeftCountry(countries: ApiMapCountry[]): ApiMapCountry {
    let closestDistance = Number.POSITIVE_INFINITY;
    let closestCountry = countries[0];

    for (const country of countries) {
        const x = country.x;
        const y = country.y;
        const distance = Math.sqrt(x * x + y * y);

        if (distance < closestDistance) {
            closestDistance = distance;
            closestCountry = country;
        }
    }

    return closestCountry;
}

function getCoordinatesForCountry(country, width, height) {
    const countryX = country.x;
    const countryY = country.y;
    const countryXPercent = countryX / width;
    const countryYPercent = countryY / height;

    return [
        countryXPercent,
        countryYPercent,
    ];
};

export default function GamePlayCreatedMapBackgroundComponent(
    {
        asset,
        mapData,
        darkness,
    }: Props,
): JSX.Element {
    const svgRef = useRef<SVGSVGElement>(null);
    const [x, setX] = useState<number>(0);
    const [y, setY] = useState<number>(0);
    const [currentCountry, setCurrentCountry] = useState<ApiMapCountry>(null);
    const [isReady, setIsReady] = useState<boolean>(false);

    const width = mapData.width;
    const height = mapData.height;

    const positionCountry = useCallback(
        (country: ApiMapCountry): void => {
            const coords = getCoordinatesForCountry(country, width, height);
            const rect = svgRef.current.getBoundingClientRect();

            setX(coords[0] * rect.width);
            setY(coords[1] * rect.height);
            setCurrentCountry(country);
        },
        [width, height, svgRef, setCurrentCountry],
    );

    const nextCountry = useCallback(
        () => {
            const neighborBorders = mapData.borders.filter(
                (border) => border.from === currentCountry.id
                    || border.to === currentCountry.id,
            );

            const randomBorder = neighborBorders[
                Math.floor(Math.random() * neighborBorders.length)
            ];

            const nextCountryId = randomBorder.from === currentCountry.id
                ? randomBorder.to
                : randomBorder.from;

            const findCountry = mapData.countries.find(
                (country) => country.id === nextCountryId,
            );

            positionCountry(findCountry);
        },
        [mapData, positionCountry, currentCountry],
    );

    useEffect(
        () => {
            if (!isReady) {
                return;
            }

            const interval = setInterval(
                () => nextCountry(),
                NEXT_COUNTRY_DELAY_MS,
            );

            return () => clearInterval(interval);
        },
        [isReady, nextCountry],
    );

    const afterInjection = useCallback(
        (svg: SVGSVGElement) => {
            svg.style.display = 'block';

            const topLeftCountry = getTopLeftCountry(mapData.countries);
            positionCountry(topLeftCountry);
            setIsReady(true);
        },
        [mapData, setIsReady, positionCountry],
    );

    const beforeInjection = useCallback(
        (svg: SVGSVGElement): void => {
            svg.removeAttribute('width');
            svg.removeAttribute('height');
            svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
            svg.style.width = '200vw';
            svg.style.display = 'none';
            svgRef.current = svg;
        },
        [width, height],
    );

    const mapComponent = <MapSVGMemoized
        asset={asset}
        afterInjection={afterInjection}
        beforeInjection={beforeInjection}
    />;

    return <div
        className={styles.mapZoom}
        style={{
            backgroundColor: mapData.mapColor || mapData.backgroundColor,
        }}
    >
        <div
            className={styles.mapZoomMapContainer}
            style={{
                marginLeft: `calc(50vw)`,
                marginTop: `calc(50vh)`,
                opacity: isReady ? 1 : 0,
                transform: `translate(-${x}px, -${y}px)`
            }}
        >
            {mapComponent}
        </div>
        <div
            className={styles.mapZoomOverlay}
            style={{
                backgroundColor: darkness != null
                    ? `rgba(0, 0, 0, ${darkness})`
                    : undefined
            }}
        >
        </div>
    </div>
}

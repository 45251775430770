'use client';

import { Icon } from "@blueprintjs/core";
import { BlueprintIcons_16Id } from '@blueprintjs/icons/src/generated/16px/blueprint-icons-16';
import { CSSProperties } from "react";

type SIconProp = {
    className?: string;
    htmlTitle?: string;
    icon: BlueprintIcons_16Id;
    color?: string;
    iconSize?: number;
    style?: CSSProperties;
    title?: string;
}


export default function SIcon(prop: SIconProp) {
    return <Icon
        className={prop.className}
        htmlTitle={prop.htmlTitle}
        icon={prop.icon}
        iconSize={prop.iconSize ?? 50}
        color={prop.color}
        style={prop.style}
        title={prop.title}
    />;
}

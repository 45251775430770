import { IconNames, IconSize } from "@blueprintjs/icons";
import pluralize from "../../src/util/pluralize";
import SIcon from "../global/sicon";
import Link from "next/link";

type Props = {
    numGames: number;
}

export default function HomeGamesHeaderCardWithGames(
    {
        numGames,
    }: Props,
): JSX.Element {
    return <div>
        <SIcon
            icon={IconNames.InfoSign} iconSize={IconSize.STANDARD}
            style={{ marginRight: '4px' }}
        />
        It&apos;s your turn in
        <Link href='/game' style={{ marginLeft: '4px' }}>
            <b>{pluralize(numGames, 'game')}</b>
        </Link>
        .
    </div>
}

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import Title from '../components/global/title';
import { Theme, useTheme } from '../components/global/theme';
import { Spinner } from '@blueprintjs/core';
import NotLoggedIn from '../components/home/notloggedin/notloggedin';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { getServerSession } from 'next-auth';
import { authOptions } from './api/auth/[...nextauth]';
import { ApiGame } from '../src/types/apigame';
import { gameGetGamesForUser } from '../src/operations/game';
import { GetGamesForUserType } from '../src/models/socket/game';
import GameGamesList from '../components/game/gameslist';
import HomeGamesHeaderCard from '../components/home/gamesheadercard';

type Props = InferGetServerSidePropsType<typeof getServerSideProps>;

export const getServerSideProps = (async (context) => {
    const { req, res } = context;
    const session = await getServerSession(req, res, authOptions);

    if (!session) {
        return {
            props: {},
        };
    }

    const userId = session.user.id;

    const activeGames: ApiGame[] = await gameGetGamesForUser(
        userId,
        {
            searchType: GetGamesForUserType.Active,
        },
    );

    const openGames: ApiGame[] = await gameGetGamesForUser(
        userId,
        {
            searchType: GetGamesForUserType.Open,
        },
    );

    const games = activeGames.filter(
        (game) => game.activeSeats.find(
            (seat) => seat.userId === userId,
        )
            || game.invites.find(
                (invite) => invite.user?.id === userId,
            ),
    );

    return {
        props: {
            games,
            openGames,
        }
    };
}) satisfies GetServerSideProps<{
    games?: ApiGame[],
    openGames?: ApiGame[],
}>

export default function Homepage(
    {
        games,
        openGames,
    }: Props,
) {
    const { data: session, status } = useSession();
    const router = useRouter();
    const { theme } = useTheme();

    const logo = theme == Theme.Dark
        ? '/spqrdark.svg'
        : '/spqr.svg';

    useEffect(
        () => {
            if (router.query.error) {
                console.error(router.query.error);
            }
        },
        [router],
    );

    if (status === 'loading') {
        return <Spinner />;
    } else if (status === 'unauthenticated') {
        return <NotLoggedIn />;
    }

    const userId = session.user.id;

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Title />
            <HomeGamesHeaderCard games={games} />
            <GameGamesList
                games={games}
                userId={userId}
            />
            <h1>Open Games:</h1>
            <GameGamesList
                games={openGames}
                userId={userId}
            />
        </div>
    );
}

import styles from './card.module.css';
import Image from 'next/image';
import NotLoggedInPlayButton from './playbutton';

export default function NotLoggedInCard(): JSX.Element {
    return (
        <div className={styles.card}>
            <div className={styles.header}>
                An online, turn-based strategy game
            </div>
            <Image
                alt='logo'
                height={458}
                priority={true}
                src={'/spqrdarkhomepage.svg'}
                width={580}
            />
            <NotLoggedInPlayButton />
        </div >
    );
}

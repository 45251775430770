import NotLoggedInTitleCard from "./titlecard";
import styles from './notloggedin.module.css';
import { ReactSVG } from "react-svg";
import NotLoggedInFeatures from "./features";
import NotLoggedInImageAndCaption, { NotLoggedInImageAndCaptionOrder } from "./imageandcaption";
import NotLoggedInMapCard from "./mapcard";

export default function NotLoggedIn(): JSX.Element {
    return <div className={styles.notLoggedIn}>
        <NotLoggedInTitleCard />
        <NotLoggedInFeatures />
        <NotLoggedInImageAndCaption
            isOdd={true}
            imageElement={<NotLoggedInMapCard mapId={14} />}
            header='Custom Maps'
            body='Unlimitless possibilities featuring multiple custom maps and map editor!'
        />
        <NotLoggedInImageAndCaption
            order={NotLoggedInImageAndCaptionOrder.ImageLast}
            imageElement={
                <ReactSVG
                    className={styles.communityIcon}
                    src='/images/home/community.svg'
                />
            }
            header='Community-Driven'
            body={
                <>
                    SPQR is actively developed by a small but passionate community of developers who fell
                    in love with the limitless possibilites on the now-defunct
                    website Warfish. They were determined to make a spiritual successor using a modern tech stack
                    and offering features such as live spectating, replay support, push notifications, mobile support and more.
                </>
            }
        />
    </div >;
}

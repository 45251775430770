import { useMemo } from 'react';
import styles from './notloggedin.module.css'

type Props = {
    children: React.ReactNode;
    isOdd?: boolean;
}

export default function NotLoggedInSection(
    {
        children,
        isOdd,
    }: Props,
): JSX.Element {
    const classes = useMemo(
        () => isOdd
            ? [styles.section, styles.section2]
            : [styles.section],
        [isOdd]
    );

    return (
        <div className={classes.join(' ')}>
            {children}
        </div>
    );
}

import React, { useMemo } from "react"
import styles from './notloggedin.module.css';
import NotLoggedInSection from "./section";

export enum NotLoggedInImageAndCaptionOrder {
    ImageFirst = 0,
    ImageLast,
}

type Props = {
    body: React.ReactNode;
    header: React.ReactNode;
    imageElement: React.ReactNode;
    isOdd?: boolean;
    order?: NotLoggedInImageAndCaptionOrder;
}

export default function NotLoggedInImageAndCaption(
    {
        body,
        header,
        imageElement,
        isOdd,
        order = NotLoggedInImageAndCaptionOrder.ImageFirst,
    }: Props,
): JSX.Element {
    const bodyContainer = useMemo(
        () => (
            <div className={styles.imageCaptionContainer}>
                <h2 className={styles.imageCaptionHeader}>
                    {header}
                </h2>
                <div>
                    {body}
                </div>
            </div>
        ),
        [header, body],
    );

    const elements = useMemo(
        () => order === NotLoggedInImageAndCaptionOrder.ImageFirst
            ? <>
                {imageElement}
                {bodyContainer}
            </>
            : <>
                {bodyContainer}
                {imageElement}
            </>,
        [order, imageElement, bodyContainer],
    );

    return (
        <NotLoggedInSection isOdd={isOdd}>
            <div className={styles.imageWithCaptionContainer}>
                {elements}
            </div>
        </NotLoggedInSection>
    );
}

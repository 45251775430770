import GamePlayCreatedMapBackgroundComponent from "../../game/play/created/mapzoomcomponent";
import NotLoggedInCard from "./card";
import { NOT_LOGGED_IN_MAP_ASSET, NOT_LOGGED_IN_MAP_DATA } from "./notloggedindata";
import styles from './titlecard.module.css';

export default function NotLoggedInTitleCard(): JSX.Element {
    return <div className={styles.titleCard}>
        <GamePlayCreatedMapBackgroundComponent
            asset={NOT_LOGGED_IN_MAP_ASSET}
            darkness={0.8}
            mapData={NOT_LOGGED_IN_MAP_DATA}
        />
        <NotLoggedInCard />
    </div>;
}

import { Intent } from "@blueprintjs/core";
import SButton from "../global/sbutton";
import { useRouter } from "next/router";
import { useCallback } from "react";

export default function HomeGamesHeaderCardCreateGameButton(): React.ReactNode {
    const router = useRouter();

    const onClick = useCallback(
        () => router.push('/game#create'),
        [router],
    );

    return <SButton
        intent={Intent.PRIMARY}
        onClick={onClick}
    >
        Create Game
    </SButton>
}

import { IconNames } from '@blueprintjs/icons'
import NotLoggedInFeatureCell from './featurecell'
import styles from './notloggedin.module.css'
import NotLoggedInSection from './section'

export default function NotLoggedInFeatures(): JSX.Element {
    return (
        <NotLoggedInSection>
            <h1>If you like HASBRO&apos;s Risk, you&apos;ll love SPQR!</h1>
            <div className={styles.columns}>
                <NotLoggedInFeatureCell
                    body='SPQR features dozens of settings and configurations, making variations endless! All this in an easy-to-use interface.'
                    header='Customizable'
                    iconName={IconNames.Cog}
                />
                <NotLoggedInFeatureCell
                    body='SPQR is built so you can play on web, tablet and phone, all through any web browser.'
                    header='Modern'
                    iconName={IconNames.MobilePhone}
                />
                <NotLoggedInFeatureCell
                    body='SPQR lets you play with teams. Ally with friends to crush your enemies!'
                    header='Team Up'
                    iconName={IconNames.Team}
                />
                <NotLoggedInFeatureCell
                    body='SPQR offers many additional features including voids, blizzards, bots, new game modes, and more!'
                    header='New Features'
                    iconName={IconNames.Lightbulb}
                />
            </div>
        </NotLoggedInSection>
    );
}

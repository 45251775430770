import { IconName } from '@blueprintjs/icons';
import SIcon from '../../global/sicon';
import styles from './notloggedin.module.css';
import React from 'react';

type Props = {
    body: React.ReactNode;
    header: React.ReactNode;
    iconName: IconName;
};

export default function NotLoggedInFeatureCell(
    {
        body,
        header,
        iconName,
    }: Props,
): JSX.Element {
    return <div className={styles.columnCell}>
        <div>
            <SIcon
                className={styles.columnCellIcon}
                icon={iconName}
                iconSize={64}
            />
        </div>
        <h2>
            {header}
        </h2>
        <div>
            {body}
        </div>
    </div>

}
